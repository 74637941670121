<template>
  <h1 @click="click">
    {{ text }}
  </h1>
  <p v-if="showSimilarity">{{ similarity }}% similarity</p>
  <p v-if="showAddToHighscore">
    <strong>New high score of {{ similarity }}% similarity!</strong> Do you want
    to add it to your scoreboard?
    <button @click="addHighScore">Add highscore</button>
  </p>
  <p v-if="showScoreboardButton">
    <small>
      <a href="#" @click="openScoreboard">Open scoreboard</a>
    </small>
  </p>
  <h2 v-if="showScoreboard">
    Scoreboard
    <button @click="synchronizeScoreboard">Synchronize</button>
  </h2>
  <table border="1" v-if="showScoreboard">
    <thead>
      <tr>
        <th>Date</th>
        <th>Similarity percentage</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(entry, index) in scoreboard" :key="index">
        <td>{{ entry.date }}</td>
        <td>{{ entry.percentage }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
const getLocalScoreBoard = () => {
  return JSON.parse(localStorage.getItem("scoreboard") ?? "[]");
};

const increaseCount = () => {
  const current = parseInt(localStorage.getItem("count") ?? 0);
  localStorage.setItem("count", current + 1);
};

const addToLocalScoreBoard = (percentage) => {
  const scoreboard = getLocalScoreBoard();
  scoreboard.push({
    percentage,
    date: new Date().toLocaleString(),
  });

  localStorage.setItem("scoreboard", JSON.stringify(scoreboard));
};

const calculateSimilarity = (text) => {
  const similarity = stringSimilarity.compareTwoStrings(defaultText, text);
  return similarity * 100;
};

const randomize = (text) => {
  let words = [];

  text.split(" ").forEach((word) => {
    let array = Array.from(word);

    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    words.push(array.join("").toLowerCase());
  });

  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const defaultText = "Maikel van Haaren";
var stringSimilarity = require("string-similarity");

export default {
  name: "App",
  data() {
    return {
      text: defaultText,
      counter: 0,
      notified: false,
      showScoreboard: false,
      scoreboard: [],
    };
  },
  computed: {
    isAbleToScore() {
      if (this.scoreboard.length && this.counter > 0) return true;
      return this.counter > 20;
    },
    showSimilarity() {
      return this.isAbleToScore && !this.showAddToHighscore;
    },
    showScoreboardButton() {
      if (this.showScoreboard) return false;
      if (this.counter == 0) return false;
      const scoreboard = getLocalScoreBoard();
      return scoreboard.length;
    },
    showAddToHighscore() {
      if (!this.isAbleToScore) return false;

      const scoreboard = getLocalScoreBoard();

      const similarity = calculateSimilarity(this.text);

      const hasHigherScore = scoreboard.filter(
        (x) => x.percentage >= similarity
      ).length;

      if (hasHigherScore) return false;

      return true;
    },
    similarity() {
      return parseFloat(calculateSimilarity(this.text)).toFixed(2);
    },
  },
  mounted() {
    this.refreshScoreboard();
  },
  methods: {
    synchronizeScoreboard(event) {
      event.preventDefault();
      alert("throw new NotImplementedException();");
    },
    refreshScoreboard() {
      this.scoreboard = getLocalScoreBoard()
        .sort((s) => s.percentage)
        .reverse();
    },
    openScoreboard(event) {
      event.preventDefault();
      this.showScoreboard = true;
    },
    addHighScore(event) {
      event.preventDefault();
      const similarity = stringSimilarity.compareTwoStrings(
        defaultText,
        this.text
      );
      addToLocalScoreBoard(similarity * 100);
      alert("Your new highscore is added to the scoreboard!");
      this.showScoreboard = true;
      this.reset();
    },
    reset() {
      this.counter = 0;
      this.text = defaultText;
      this.refreshScoreboard();
    },
    click(event) {
      event.preventDefault();
      this.counter++;
      increaseCount();
      this.text = randomize(defaultText);
      if (this.text === defaultText) {
        alert("Did you know what just happened?");
        alert("The name was changed back, by random, to my original name!");
        alert("I will buy you a beer! 🍺");
        this.reset = true;
      }
    },
  },
};
</script>

<style></style>
